<script>

</script>

<style>
  .footer {
    margin-top: 15px;
    background: #f9b218;
    height: 70px;
    padding: 10px;
  }

  .subfooter {
    background: #153252;
    height: 40px;
    padding: 5px;
  }

  .socialicons {
    text-align: center;
  }
  .subfooter {
    text-align: center;
    padding: 20px;
    height: auto;
  }
  .subfooter a {
    color: white;
    margin-left: 10px;
    margin-right: 10px;
  }
  .development {
    margin-top: 20px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.7);
    font-family: Roboto;
    font-size: 14px;
  }
  .echar {
    background-color: rgba(255, 255, 255, 0.2);
  }
</style>

<div class="footer">
  <div class="socialicons">
    <a href="https://www.facebook.com/uniplazahonduras/">
      <img src="./img/FBfooter.png" alt="facebook" />
    </a>
    <a href="https://www.facebook.com/uniplazahonduras/">
      <img src="./img/TWfooter.png" alt="twitter" />
    </a>
    <a href="https://www.instagram.com/uniplazahn/">
      <img src="./img/IGfooter.png" alt="instagram" />
    </a>
  </div>
</div>
<div class="subfooter">

  <a href="/about">Acerca</a>
  <a href="http://www.malluniplaza.com/contacto/">Contacto</a>
  <a href="http://www.malluniplaza.com/">Malluniplaza.com</a>
  <br />
  <hr class="echar" />
  <div class="development">
    Malluniplaza.com - Desarrollo por BoostDigital Honduras
  </div>

</div>
